.card {
  border-radius: 4px;
  border: 1px solid #e2e5eb;
  padding: 12px;
}

.card.reports {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.infoHead {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #e2e5eb;
  padding: 5px;
}

.page-length {
  width: 80px;
  padding: 8px 10px !important;
  font-size: 14px !important;
  border: 1px solid #e2e5eb !important;
  border-radius: 4px;
  background: url(./assets/caretDown.png) no-repeat right #fff;
  background-size: 25px 25px;
  -webkit-appearance: none;
  background-position-x: 40px;
  cursor: pointer;
}

.paginate-button {
  background-color: white;
  width: 35px;
  height: 35px;
  border: 1px solid #e2e5eb;
  border-radius: 4px;
  margin: 0 6px;
  font-size: 15px !important;
}

.paginate-button:hover {
  background-color: #e2e5eb;
}

.paginate-button:disabled {
  background-color: #d8d9db;
  color: #a8a8a8;
}

.page-no {
  padding: 5px 6px;
}

.page-no:hover {
  color: #0750e2;
  cursor: pointer;
}

.page-no.active {
  color: #0750e2;
  background-color: #e2e5eb;
  border-radius: 4px;
}

.more-actions {
  position: absolute;
  background-color: white;
  border: 1px solid #e2e5eb;
  border-radius: 4px;
  width: 160px;
  padding: 4px;
}

.action {
  list-style-type: none;
  width: 100%;
  cursor: pointer;
  padding: 4px;
}

.action:hover {
  background-color: #f1f2f4;
}

.kebab-icon:hover {
  cursor: pointer;
  padding: 5px 0;
  border-radius: 4px;
  background-color: #e2e5eb;
}

.clickable {
  cursor: pointer;
}

.sort-icon {
  float: right;
  height: 18px;
  width: 18px;
}

.url-text:hover {
  text-decoration: underline !important;
}

.tool-tip {
  transform: translateY(-25px) translateX(25px);
  position: absolute;
  padding: 2px 10px;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.712);
  z-index: 2 !important;
  color: white;
}

.drop-down {
  border: 1px solid #e2e5eb;
  width: 150px;
  border-radius: 4px;
}

.drop-down-menu {
  border: 1px solid #e2e5eb;
  align-items: flex-start;
  width: 152px;
  position: absolute;
  transform: translateY(45px);
  z-index: 2;
  border-radius: 4px;
  background-color: white !important;
}

.drop-down-item {
  padding: 4px 8px;
  width: 100%;
}

.drop-down-item:hover {
  background-color: #e2e5eb;
}
