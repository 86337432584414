.text-selected {
  color: #0750e2;
}

.tab-section-border {
  border-bottom: 1px solid #0750e2;
}

.tabs {
  max-width: 25%;
}

.tab-false:hover {
  color: #0750e2;
}

.tab-active {
  color: #0750e2;
}

.outline-active {
  border: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 3px solid #0750e2;
}

.text-danger {
  font-size: 12px;
}

.keywords-loading {
  position: absolute;
  transform: translateY(-165px);
}

.back-button {
  position: absolute;
  top: 12px;
  right: 24px;
}

.circle {
  border-radius: 50%;
  border: 4px solid #efefef;
  padding: 8px;
  background-color: white !important;
  z-index: 1;
}

.circle.active {
  background-color: #0750e2 !important;
}

.stepper {
  width: 800px;
}

.stepper-line {
  height: 4px;
  width: 635px;
  background-color: #efefef;
  position: absolute;
  top: 123px;
}

.welcome {
  height: 30%;
  width: 30%;
  margin-top: 20vh;
}

.options-filter {
  margin-right: 12px;
  margin-bottom: 5px;
}

.error-text {
  position: absolute;
  transform: translateY(20%);
}
