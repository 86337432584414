.sidebar {
  padding: 0;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 275px;
  z-index: 1;
  position: sticky;
  top: 0;
  left: 0;
  border-right: 1px solid #e2e5eb;
  transition: 0.1s ease-in;
}

.collapsed {
  width: 48px;
  transition: 0.1s ease-in;
}

.logo-zone {
  height: 60px;
}

.menu {
  margin: 0 12px;
}

.sidebar-menu-item {
  text-decoration: none;
  color: black;
  display: block;
  height: 48px;
  font-size: 14px;
  padding: 12px;
}

.sidebar-menu-item:hover {
  text-decoration: none;
}

.logout-area {
  padding: 0 12px;
}

.collapse-button {
  position: absolute;
  background: white;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #273b4e1a;
  left: 100%;
  top: 45px;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 2;
  transition: 0.1s ease-in-out;
}

.rotate-button {
  transform: rotate(180deg) translateX(10px);
}

.text-selected {
  color: #0750e2 !important;
}
