.message-queue {
  position: fixed;
  bottom: 0;
  width: 40%;
}

.snackbar-green {
  border-left: 4px solid #2ad130 !important;
  background-color: #edffee !important;
}

.snackbar-red {
  border-left: 4px solid #d1302a !important;
  background-color: #ffeeed !important;
}

.cross {
  font-size: 16px;
  margin-right: 12px;
  line-height: 0.5;
}

.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  margin-right: 8px;
}

.check-icon {
  position: absolute;
  transform: translateX(-1px) translateY(-1px);
  border-radius: 2px;
}

.radio-button {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #ced4da;
}

.radio-button.filled {
  background-color: white;
  border: 2px solid #0750e2;
}

.radio-fill {
  background-color: #0750e2;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.helper {
  padding: 2px 3px 1px 3px;
  line-height: 0.9;
  border-radius: 50%;
  font-size: 11px !important;
  cursor: help;
}

.helper-white {
  border: 1px solid white;
  color: white;
}

.helper-gray {
  border: 1px solid #8494a4;
  color: #8494a4;
}

.helper-text {
  position: absolute;
  line-height: 1.2;
  background-color: white;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px !important;
  transform: translateY(-80%) translateX(7%);
  z-index: 2;
  min-width: 240px;
}

.drop-down {
  border: 1px solid #e2e5eb;
  width: 150px;
  border-radius: 4px;
  color: #000000cc;
}

.drop-down-menu {
  border: 1px solid #e2e5eb;
  width: 152px;
  position: absolute;
  transform: translateY(5%) !important;
  z-index: 2;
  border-radius: 4px;
  background-color: white !important;
}

.drop-down-item {
  width: 100%;
  cursor: pointer;
}

.drop-down-item:hover {
  background-color: #e2e5eb;
}

.ninadata-loader {
  background-color: rgba(0, 0, 0, 0.432);
  color: white;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
}

.loading-text {
  font-size: 55px;
}

.ellipses {
  position: absolute;
  font-size: inherit;
}

