.label-text {
  color: #8494a4;
  font-family: "Roboto-bold";
  margin-right: 2px !important;
}

.widgets {
  border: 1px solid #e2e5eb;
  border-radius: 4px;
  padding: 12px;
}

.text-active {
  font-family: "Roboto-bold";
  color: #0750e2;
}
